export default theme => ({
    form: {
        backgroundColor: theme.palette.primary.main,
        margin: '50px auto',
        maxWidth: 768,
        padding: 32,
        '& h2': {
            color: 'white',
            fontWeight: 'normal',
            letterSpacing: '0.016em',
            fontSize: 32,
            marginTop: 0,
            marginBottom: 12,
        },
        '& p': {
            color: 'white',
        },
        '& label': {
            color: 'white',
            fontSize: 12,
            lineHeight: 1.6,
            letterSpacing: '0.152em',
            margin: 0,
            fontFamily: 'prometo, sans-serif',
        },
        '& input': {
            fontFamily: 'prometo, sans-serif',
            backgroundColor: 'transparent',
            borderRadius: 20,
            fontSize: 12,
            lineHeight: 2,
            padding: '7px 20px',
            border: '1px solid white',
            marginBottom: 24,
            color: 'white',
            outline: 'none',
            display: 'block',
            width: '100%',
        },
        '& input[type="submit"]': {
            width: 'auto',
            backgroundColor: 'white',
            color: 'rgb(15,28,50)',
            fontFamily: 'prometo, sans-serif',
            fontSize: 14,
            display: 'block',
            padding: '10px 20px',
            letterSpacing: '0.016em',
            fontWeight: 'bold',
            textAlign: 'center',
            backgroundPosition: 'center right 24px',
            backgroundRepeat: 'no-repeat',
            outline: 'none',
            boxShadow: '0 8px 10px rgb(0 0 0 0.8)',
            cursor: 'pointer',
            borderRadius: 25,
            border: 'none',
        }
    }
})

//136