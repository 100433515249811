import React, { Component } from 'react';
import {graphql} from 'gatsby';

import Layout from '../../components/layout';
import AccountCreateForm from '../../components/Account/AccountCreateForm';

class SalesOffers extends Component {
  render() {
    return (
      <Layout
        meta={{
          ...this.props.data.wpPage.Meta,
          canonical: '/account/create/'
        }}
        path={'a'}
        cta={'a'}
        themeOptions={this.props.data.wp.themeOptions}
      >
        <AccountCreateForm />
      </Layout>
    )

  }
}

export const query = graphql`
  query {
    wpPage(uri: {eq: "/"}) {
      id
      Meta {
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
      }
    }
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
        }
      }
    }
  }
`;

export default SalesOffers;