import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { postData } from './actions';

import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

function QuoteBuilder() {
  const classes = useStyles();

  const [forename, setForename] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState();
  const [company, setCompany] = useState('');
  const [postcode, setPostcode] = useState('');
  const [status, setStatus] = useState();

  function handleSubmit(e) {
    if (e) e.preventDefault();

    const request = postData({
      forename,
      surname,
      email,
      telephone: phone,
      company,
      company_postcode: postcode
    });

    request
    .then(res => {
      if (res.status === 200 && res.data.success === true) {
        setStatus('success');
      } else {
        setStatus('error');
      }
    })
    .catch(e => setStatus('error'));
  }

  return <div className={classes.form}>
    {status === 'success' && <h2 className={classes.success}>Thank you.</h2>}
    {status === 'success' && <p>A member of the team will be in touch.</p>}

    {status !== 'success' && <h2>Request an account</h2>}
    {!status && <p>Fill in the form below to register your interest in getting an account and one of our team will be in touch.</p>}

    {status === 'error' && <p>Your request couldn't be proceed, please contact <a href="mailto:marketing@tyde.co.uk">marketing@tyde.co.uk</a></p>}

    {status !== 'success' && <form onSubmit={(e) => handleSubmit(e)}>
      <label htmlFor="name">First Name*</label>
      <input type="text" name="name" placeholder="John" required onChange={(e) => setForename(e.target.value)} />

      <label htmlFor="surname">Last Name*</label>
      <input type="text" name="surname" placeholder="Smith" required onChange={(e) => setSurname(e.target.value)} />

      <label htmlFor="email">Email*</label>
      <input type="email" name="email" placeholder="example@gmail.com" required onChange={(e) => setEmail(e.target.value)} />

      <label htmlFor="phone">Contact Number*</label>
      <input type="string" name="phone" placeholder="+44 000 000 00" required onChange={(e) => setPhone(e.target.value)} />

      <label htmlFor="company">Company Name*</label>
      <input type="text" name="company" placeholder="Tyde" required onChange={(e) => setCompany(e.target.value)} />

      <label htmlFor="postcode">Postcode*</label>
      <input type="text" name="postcode" placeholder="LON 1" onChange={(e) => setPostcode(e.target.value)}/>
    
      <input type="submit" value="Submit" />

      <p>*Field is required</p>

    </form>}

  </div>;
}

export default QuoteBuilder;